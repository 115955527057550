import {
  selectConversationIsLoading,
  selectConversationMessages,
  selectConversationSubmitBlocked,
  selectSelectedFiles,
} from '@/chat-common/store/chat-v2.selectors'
import { ChatV2MessageType } from '@/chat-common/store/chat-v2.slice'
import { chatV2HandleSendMessage } from '@/chat-common/util/chat-v2-handle-send-message'
import { useOnlineStatus } from '@/context/online-status-context'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'

type ChatRetryUserQueryButtonProps = {
  conversationId: string
}

/**
 * Chat Retry Button
 * A component that represents a chat retry button.
 *
 * Renders IF:
 * - The most recent message is a client error
 * - There is a previous user query with a message to send
 * @returns
 */
export default function ChatRetryUserQueryButton(props: ChatRetryUserQueryButtonProps) {
  const { conversationId } = props
  const isOnline = useOnlineStatus()

  // Redux state selectors
  const selectedFilePaths = useAppSelector((state: RootState) => selectSelectedFiles(state, { chatId: conversationId }))
  const submitBlocked = useAppSelector((state: RootState) =>
    selectConversationSubmitBlocked(state, {
      chatId: conversationId,
      keys: selectedFilePaths,
    })
  )
  const conversationIsLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId: conversationId }))
  const messages = useAppSelector((state: RootState) => selectConversationMessages(state, { chatId: conversationId }))

  // Get the most recent user query message
  const getMostRecentUserQuery = () => {
    const userQueryMessages = Object.values(messages).filter((message) => message.metadata.message_type == ChatV2MessageType.user_query)
    const mostRecentUserQuery = userQueryMessages.reverse()[0] ?? null
    return mostRecentUserQuery
  }

  const mostRecentUserQuery = getMostRecentUserQuery()

  if (mostRecentUserQuery && isOnline) {
    return (
      <>
        {submitBlocked && !conversationIsLoading && <div className={'text-red-700 text-xs pb-1'}>Please correct any form errors before selecting retry.</div>}
        <button
          className={`flex-auto items-center rounded-md bg-brand-500 border-[1px] border-brand-500 px-3 py-2 mt-1 mb-5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400 ${
            submitBlocked ? 'opacity-50' : 'opacity-100'
          }`}
          disabled={submitBlocked}
          onClick={() => {
            chatV2HandleSendMessage({
              chatId: conversationId,
              onComplete: () => {
                return
              },
              messageOverride: mostRecentUserQuery.text,
            })
          }}
        >
          Retry
        </button>
      </>
    )
  }
  return <div className={'p-5 bg-green-200'}>OK</div>
}
