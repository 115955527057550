import { ChatV2Feature, chatV2SetConversationCurrentTextInput } from '@/chat-common/store/chat-v2.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { getAuth } from 'firebase/auth'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { chatV2HandleSendMessage } from '@/chat-common/util/chat-v2-handle-send-message'
import {
  selectConversationCurrentTextInput,
  selectConversationFeature,
  selectConversationIsLoading,
  selectConversationSubmitBlocked,
  selectSelectedFiles,
} from '@/chat-common/store/chat-v2.selectors'
import { useRef } from 'react'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ArrowUpIcon } from '@radix-ui/react-icons'
import FileActionMenu from './assistant/file-action-menu'

type ChatFormInputProps = {
  conversationId: string
}

/**
 * The Chat Form component.
 *
 * Contains the chat form source controls, and the text area input.
 * Has additional logic for single message features, anonymous users.
 * @returns
 */
export default function ChatFormInput(props: ChatFormInputProps) {
  const { conversationId } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Auth
  const auth = getAuth()
  const isAnonymous = auth.currentUser?.isAnonymous ?? false

  // Conversation values
  const selectedFilePaths = useAppSelector((state: RootState) => selectSelectedFiles(state, { chatId: conversationId }))
  const currentTextInput = useAppSelector((state: RootState) => selectConversationCurrentTextInput(state, { chatId: conversationId }))
  const isLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId: conversationId }))
  const feature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  const isAssistantFeature = feature === ChatV2Feature.assistant

  // Form submit disabled
  const submitBlocked = useAppSelector((state: RootState) =>
    selectConversationSubmitBlocked(state, {
      chatId: conversationId,
      keys: selectedFilePaths,
    })
  )

  const submitDisabled = submitBlocked || currentTextInput.trim() == ''

  /**
   * Handle Send Message on the Chat Window Form
   */
  const handleSendMessage = () => {
    chatV2HandleSendMessage({
      chatId: conversationId,
      onComplete: () => {
        // Update the height of the text area
        if (textAreaRef.current) {
          textAreaRef.current.style.height = '40px' // starting height of area
        }

        // Track the new message
        trackEvent(AnalyticsEvent.NewChatMessage, { feature: feature })
      },
    })
  }

  return (
    <div>
      {/* Anon user - Save Your Work Buttons */}
      {isAnonymous && (
        <div className={'flex justify-center w-full text-xs text-center'}>
          <div className={'flex gap-x-2 items-end bg-brand-800 bg-opacity-80 py-1 px-4 rounded-t-md'}>
            <div className={'text-white'}>Save your work:</div>
            <button
              className={'text-white underline px-1 hover:text-opacity-80'}
              onClick={() => {
                dispatch(
                  openAuthDialog({
                    authDialogType: AuthDialogType.SIGN_UP,
                  })
                )
              }}
            >
              Sign up
            </button>
            <button
              className={'text-white underline hover:text-opacity-80'}
              onClick={() => {
                dispatch(
                  openAuthDialog({
                    authDialogType: AuthDialogType.SIGN_IN,
                  })
                )
              }}
            >
              Sign in
            </button>
          </div>
        </div>
      )}

      {/* Chat Form - Text Input */}
      {/* Legacy Form */}
      {!isAssistantFeature && (
        <>
          <form className="mt-auto w-full p-2 grid gap-x-2 items-end grid-cols-[auto_40px] border border-brand-neutral-400 rounded-lg bg-brand-neutral-50">
            <textarea
              ref={textAreaRef}
              className="resize-none flex-1 p-2 border-none focus:ring-0 bg-transparent"
              value={currentTextInput}
              onChange={(e) => {
                dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversationId, text: e.target.value }))

                const target = e.target as HTMLTextAreaElement
                target.style.height = 'auto'
                target.style.height = `${target.scrollHeight}px`
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault() // Prevent default to avoid line break in textarea

                  if (!submitDisabled) {
                    handleSendMessage() // Call your form's submit handler
                  }
                }
              }}
              placeholder={feature === ChatV2Feature.contractanalysis ? 'Provide context here' : 'Enter a prompt here'}
              rows={1}
              style={{ lineHeight: '1.5', maxHeight: '12rem' }}
            />

            {isLoading ? (
              <div className={'w-10 h-10 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
                <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
              </div>
            ) : (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleSendMessage()
                }}
                disabled={submitDisabled}
                className={`w-10 h-10 bg-brand-500 text-white rounded-md grid place-items-center ${submitDisabled ? 'opacity-50' : 'opacity-100'}`}
              >
                <ArrowUpIcon width="20" height="20" />
              </button>
            )}
          </form>
        </>
      )}

      {/* Assistant Form */}
      {isAssistantFeature && (
        <form className="mt-auto z-10 relative w-full p-2 flex items-end border border-brand-neutral-400 rounded-lg bg-brand-neutral-50">
          <textarea
            ref={textAreaRef}
            className="resize-none flex-1 p-2 border-none focus:ring-0 bg-transparent"
            value={currentTextInput}
            onChange={(e) => {
              dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversationId, text: e.target.value }))

              const target = e.target as HTMLTextAreaElement
              target.style.height = 'auto'
              target.style.height = `${target.scrollHeight}px`
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault() // Prevent default to avoid line break in textarea
                if (!submitDisabled) {
                  handleSendMessage()
                }
              }
            }}
            placeholder={'Enter a prompt here'}
            rows={1}
            style={{ lineHeight: '1.5', maxHeight: '12rem' }}
          />

          <div className="mr-3">
            <FileActionMenu disabled={isLoading} conversationId={conversationId} />
          </div>

          {isLoading ? (
            <div className={'w-10 h-10 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
              <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
            </div>
          ) : (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                handleSendMessage()
              }}
              disabled={submitDisabled}
              className={`w-10 h-10 bg-brand-500 text-white rounded-md grid place-items-center ${submitDisabled ? 'opacity-50' : 'opacity-100'}`}
            >
              <ArrowUpIcon width="20" height="20" />
            </button>
          )}
        </form>
      )}

      {/* Below form */}
      <div className={'px-1'}>
        <p className={'text-xs text-brand-neutral-600 text-left mt-1 hidden sm:block'}>new line: shift + enter</p>
      </div>
    </div>
  )
}
