import { useAppSelector } from '@/store/store-hooks'
import { selectFileUploadTasks } from '@/store/selectors/file-drive-upload-tasks-reducers.selector'
import { CircularProgressDeterminate } from '@/components/loaders/CircularProgressDeterminate'

const FileDriveUploadStatusList = () => {
  const tasks = useAppSelector((state) => selectFileUploadTasks(state))

  return (
    <>
      {
        Object.keys(tasks).map((taskId) => {
          const task = tasks[taskId]
          return (
            <div key={taskId} className="mt-4 grid grid-cols-[auto_30px] items-center" >
              <div className="inline-block align-middle text-sm overflow-hidden">
                <p>{task.name}</p>
              </div>
              <div className="inline-block align-middle">
                <CircularProgressDeterminate value={task.progress} error={!!task.errorMessage} />
              </div>
              {task.errorMessage && <p className="text-red-600 text-xs">{task.errorMessage}</p>}
            </div>
          )

        })
      }
    </>
  )
}
export { FileDriveUploadStatusList }