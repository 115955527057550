import { useEffect, useState } from 'react'
import { getBrandFromHost, getFaviconFromBrand } from '@/util/enterprise'
import OnboardingWelcome from '../components/OnboardingWelcome'

interface ViewportState {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

/**
 * Custom hook to manage viewport state
 *
 * @returns {ViewportState} Object containing viewport state and device type flags
 */
const useViewport = (): ViewportState => {
  const [width, setWidth] = useState(window.innerWidth)

  const BREAKPOINTS = {
    MOBILE: 640,
    TABLET: 1024,
  } as const

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return {
    isMobile: width < BREAKPOINTS.MOBILE,
    isTablet: width >= BREAKPOINTS.MOBILE && width < BREAKPOINTS.TABLET,
    isDesktop: width >= BREAKPOINTS.TABLET,
  }
}

const Onboarding: React.FC = () => {
  const hostBrand = getBrandFromHost()
  const faviconUrl = getFaviconFromBrand(hostBrand)
  const viewport = useViewport()

  return (
    <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-10 py-12 bg-brand-neutral-50 rounded-lg shadow-lg flex flex-grow flex-col space-y-8">
      <div className="flex flex-col gap-y-2.5">
        <h1 className="text-4xl leading-4xl font-semibold flex items-center tex-brand-950">
          <img className="flex mr-3 h-8 w-8" src={faviconUrl} alt={hostBrand} />
          Welcome!
        </h1>
        <p className="text-base leading-6 text-brand-neutral-600 -tracking-[0.32px]">
          Please answer a few questions to help {hostBrand.charAt(0).toUpperCase() + hostBrand.slice(1)} personalize your experience.
        </p>
      </div>
      <OnboardingWelcome visible={true} viewport={viewport} />
    </div>
  )
}

export default Onboarding
