import { createListenerMiddleware } from '@reduxjs/toolkit'
import { store } from '@/store/store'
import { GlobalToastType } from '@/constants/constants-ui'
import { openGlobalToast } from '@/store/slices/global-toast.slice'

/**
 * Creates a listener middleware for handling toast notifications.
 */
export const toastListenerMiddleware = createListenerMiddleware()

/**
 * Shows a global toast notification.
 *
 * @param message The message to display in the toast
 * @param type The type of toast to show (defaults to SUCCESS)
 */
export function showGlobalToast(message: string, type: GlobalToastType = GlobalToastType.SUCCESS) {
  store.dispatch(
    openGlobalToast({
      type,
      message,
      durationMs: 2000,
    })
  )
}

// Add global type definition
declare global {
  interface Window {
    showPaxtonToast: typeof showGlobalToast
  }
}

// Initialize the global toast function
if (typeof window !== 'undefined') {
  window.showPaxtonToast = showGlobalToast
}
