import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { Article } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import React from 'react'
import { FileNode } from '../hooks/useProcessedFiles'
import { getFileStatus } from './get-file-status'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { FileStatus as DriveFileStatus } from '@/routes/dashboard/file-drive/schemas/files-drive-schema'

export type FileStatus = 'processing' | 'complete' | 'ocr' | 'error'

//TODO: Clean up the status config when file drive is ready to use.
const statusConfig: Record<FileStatus | DriveFileStatus, { icon: React.ReactNode; toolTipMessage?: string }> = {
  processing: {
    icon: <CircularProgressContinuousSized className="ml-1 mr-1" size={16} thickness={7} />,
  },
  complete: {
    icon: <Article />,
  },
  completed: {
    icon: <Article />,
  },
  ocr: {
    icon: <ExclamationCircleIcon height={20} className={'text-white bg-red-600 rounded-full'} />,
    toolTipMessage: 'Error processing file, please try again',
  },
  failed: {
    icon: <ExclamationCircleIcon height={20} className={'text-white bg-red-600 rounded-full'} />,
    toolTipMessage: 'Error processing file, please try again',
  },
  error: {
    icon: <ExclamationCircleIcon height={20} className={'text-white bg-red-600 rounded-full'} />,
    toolTipMessage: 'Error processing file, please try again',
  },
}


export default function DynamicFileStatusIcon({ file }: { file: FileNode }) {
  const { status } = getFileStatus(file)
  const { icon, toolTipMessage } = statusConfig[status as FileStatus | DriveFileStatus]

  return (
    <div className="inline-flex align-middle">
      {toolTipMessage ? (
        <Tooltip title={toolTipMessage} placement="top">
          <span>{icon}</span>
        </Tooltip>
      ) : (
        <span>{icon}</span>
      )}
    </div>
  )
}

export function DynamicStatusIconFromFile(status: DriveFileStatus) {
  const { icon, toolTipMessage } = statusConfig[status]
  return (
    <div className="inline-flex align-middle">
      {toolTipMessage ? (
        <Tooltip title={toolTipMessage} placement="top">
          <span>{icon}</span>
        </Tooltip>
      ) : (
        <span>{icon}</span>
      )}
    </div>
  )
}
