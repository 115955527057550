import { getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import * as Sentry from '@sentry/browser'
import { getAuth } from 'firebase/auth'
import { kGcsUserFileDriveUploadsBucketRef } from '@/constants/constants-gcs'
import { NodeType } from '../schemas/files-drive-schema'

export type UploadableDriveFile = {
  id: string
  file: File | Blob
  name: string
  user_id: string
  folder_path: string
  type: NodeType
}

type UploadDriveFileHandlerArgs = {
  uploadableFile: UploadableDriveFile
  currentFolder: string | null
  onProgress: (progress: number) => void
  onError: (error: Error) => void
  onComplete: () => void
}

/**
 * Upload File Handler
 * Based on Firebase Storage "Monitor Upload Progress" documentation
 * https://firebase.google.com/docs/storage/web/upload-files#monitor_upload_progress
 * 
 * Drive version of the upload handler for the new file drive. 
 * 
 * @param args - The arguments for the upload handler.
 * @param args.uploadableFile - The file to upload.
 * @param args.currentFolder - The current folder to upload the file to.
 * @param args.onProgress - The function to call when the upload progresses.
 * @param args.onError - The function to call when the upload errors.
 * @param args.onComplete - The function to call when the upload completes.
 *      
 * @returns - The upload task object with the upload task id. 
 * 
 * @example
 * const uploadTask = uploadDriveFileHandler({
 *   uploadableFile: uploadableFile,
 *   currentFolder: currentFolder,
 *   onProgress: onProgress,
 *   onError: onError,
 *   onComplete: onComplete,
 * })
 */
export default function uploadDriveFileHandler(args: UploadDriveFileHandlerArgs) {
  const { uploadableFile, currentFolder, onProgress, onError, onComplete } = args

  // Get the user's auth id
  const auth = getAuth()
  const userId = auth.currentUser?.uid

  if (userId === undefined) {
    throw new Error('Cannot run the uploadFileHandler: User is not authenticated. userId is undefined.')
  }

  // Assert that file of type Blob has a fileName
  if (uploadableFile.file instanceof Blob && !uploadableFile.name) {
    onError(new Error('Unexpected error uploading file.'))
    throw new Error('Cannot run the uploadFileHandler: UploadableFile type Blob must have a fileName.')
  }

  // Declare Firebase Storage references
  const storage = getStorage(undefined, kGcsUserFileDriveUploadsBucketRef)
  const currentFolderRef = ref(storage, `users/${userId}/${currentFolder ?? ''}` + uploadableFile.id)

  // Set the metadata for the upload
  const metadata = {
    customMetadata: {
      file_name: uploadableFile.name,
      user_id: userId,
      folder_path: uploadableFile.folder_path ?? '',
    }
  }

  const uploadTask = uploadBytesResumable(currentFolderRef, uploadableFile.file, metadata)

  // Start the upload task
  // Register three observers:
  // 1. 'state_changed' observer, called any time the state changes
  // 2. Error observer, called on failure
  // 3. Completion observer, called on successful completion
  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      onProgress(progress)
    },
    (error) => {
      // Handle unsuccessful uploads
      onError(error)
      Sentry.captureException(error)
    },
    () => {
      // Handle successful uploads on complete
      onComplete()
    }
  )
}