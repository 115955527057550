import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

// Selector that return the files drive state
export const selectFilesDriveState = createSelector(
  (state: RootState) => state.filesDrive,
  (filesDrive) => filesDrive
)

export const selectFoldersContent = createSelector(
  // Input selectors
  (state: RootState) => state.filesDrive.folders,
  (state: RootState) => state.filesDrive.files,
  (state: RootState) => state.filesDrive.loading,
  (_state: RootState, folderId: string | null) => folderId,
  (folders, files, loading, folderId) => {
    if (!folderId || folderId === '') {
      return {
        folders,
        files,
        loading,
      }
    }

    const filteredFolders = folders.filter((f) => f.folder_id === folderId)
    const filteredFiles = files.filter((f) => f.folder_id === folderId)

    return {
      folders: filteredFolders,
      files: filteredFiles,
    }
  }
)

export const selectCurrentFolderId = createSelector(
  (state: RootState) => state.filesDrive.currentFolderId,
  (currentFolderId) => currentFolderId
)

// Start of Selection
// Selector that returns the current folder node along with its parent folder based on the folder id
export const selectCurrentFolderMeta = createSelector(
  (state: RootState) => state.filesDrive.folders,
  (state: RootState) => state.filesDrive.currentFolderId,
  (state: RootState) => state.filesDrive.currentFolderMeta,
  (folders, currentFolderId, currentFolderMeta) => {
    // First try to get from currentFolderMeta
    if (currentFolderMeta?.id === currentFolderId) {
      return currentFolderMeta
    }

    // Fallback to folders array if needed
    const currentFolder = folders.find((f) => f.id === currentFolderId)
    if (currentFolder?.folder_id) {
      const parentFolder = folders.find((f) => f.id === currentFolder.folder_id)
      return { ...currentFolder, parentFolder }
    }
    return currentFolder
  }
)

export const selectLoading = createSelector(
  (state: RootState) => state.filesDrive.loading,
  (loading) => loading
)
