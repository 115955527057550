import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UploadTaskStatusDrive } from '@/routes/dashboard/file-drive/components/FileDriveDropZone'

export type FileDriveUploadTasksState = {
  uploadTasks: Partial<Record<string, UploadTaskStatusDrive>>
}

const initialState: FileDriveUploadTasksState = {
  uploadTasks: {},
}

/**
 * FileUploadTasksState Required Types
 * KEY: sourcePath
 */
export const fileDriveUploadTasksSlice = createSlice({
  name: 'fileDriveUploadTasksSlice',
  initialState: initialState,
  reducers: {
    addDriveFileUploadTask: (state, action: PayloadAction<UploadTaskStatusDrive[]>) => {
      action.payload.forEach((task: UploadTaskStatusDrive) => {
        state.uploadTasks[task.uploadableFileId] = task
      })
    },
    updateDriveFileUploadTask: (
      state: FileDriveUploadTasksState,
      action: PayloadAction<{ progress: number; id: string; complete?: boolean; errorMessage?: string }>
    ) => {
      const task = state.uploadTasks[action.payload.id]
      if (!task) {
        return // Early exit if task doesn't exist
      }

      task.progress = action.payload.progress
      task.complete = action.payload.complete ?? false
      task.errorMessage = action.payload.errorMessage ?? '' // only default error message
    },
    deleteDriveFileUploadTask: (state, action: PayloadAction<{ uploadableFileId: string }>) => {
      delete state.uploadTasks[action.payload.uploadableFileId]
    },

    removeFileProcessing: (state, action: PayloadAction<{ uploadableFileId: string }>) => {
      const { uploadableFileId } = action.payload
      delete state.uploadTasks[uploadableFileId]
    },
  },
})

export const { addDriveFileUploadTask, updateDriveFileUploadTask, deleteDriveFileUploadTask, removeFileProcessing } = fileDriveUploadTasksSlice.actions

export default fileDriveUploadTasksSlice.reducer
