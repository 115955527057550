import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { useEffect, useRef } from 'react'
import { UploadTaskStatusDrive } from './FileDriveDropZone'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { selectCompleteUploadTasks } from '@/store/selectors/file-drive-upload-tasks-reducers.selector'
import { FileDriveUploadStatusList } from './FileDriveUploadStatusList'

interface FileUploadStatusPopoverProps {
  expanded: boolean
  tasks: UploadTaskStatusDrive[]
  setExpanded: (expanded: boolean) => void
}

/**
 * FileDriveUploadStatusPopover - modified from tailwindcss-radix boilerplate
 * https://github.com/ecklf/tailwindcss-radix/blob/main/demo/components/popover.tsx
 * 
 * Based on new file drive upload tasks, new components to show the upload statuses. 
 * 
 * @param props
 * @returns 
 */
const FileDriveUploadStatusPopover = (props: FileUploadStatusPopoverProps) => {
  const { expanded, setExpanded, tasks } = props

  const completeTasks = useSelector((state: RootState) => selectCompleteUploadTasks(state))

  // Scroll to the bottom of the list when tasks update
  const scrollRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (scrollRef.current) {
      const { scrollHeight } = scrollRef.current
      scrollRef.current.scrollTo({
        top: scrollHeight,
        behavior: 'smooth', // Smoothly scroll
      })
    }
  }, [tasks])

  return (
    <div className={`z-50 fixed bottom-0 right-6 inline-block text-left w-72 sm:w-96`}>
      <div className={'overflow-hidden rounded-t-lg bg-brand-neutral-50 shadow'}>
        <div className="mb-4">
          <div className="px-5 pt-5 flex justify-between cursor-pointer" onClick={() => setExpanded(!expanded)}>
            {completeTasks.length > 0 ? (
              <div className="text-sm text-brand-neutral-600">
                {completeTasks.length} file{completeTasks.length > 1 ? 's' : ''} uploaded
              </div>
            ) : (
              <div className="text-sm text-brand-neutral-600">No files uploaded</div>
            )}
            <div>{expanded ? <KeyboardArrowDown /> : <KeyboardArrowUp />}</div>
          </div>
          <hr className={(expanded ? ' h-px mt-4' : ' h-0') + ' bg-brand-neutral-200 border-0 dark:bg-brand-neutral-700'}></hr>

          <div className={'overflow-y-scroll  transition-all' + (expanded ? ' h-96' : ' h-0')} ref={scrollRef}>
            <div className={'px-5'}>
              <FileDriveUploadStatusList />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export { FileDriveUploadStatusPopover }