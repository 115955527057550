import { UploadTaskStatusDrive } from '@/routes/dashboard/file-drive/components/FileDriveDropZone'
import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

/**
 * SELECTOR: Selects uploading tasks list with the all the current tasks
 */
export const selectFileUploadTasks = createSelector(
  (state: RootState) => state.uploadDriveFileTasksState.uploadTasks as unknown as Record<string, UploadTaskStatusDrive>,
  (uploadTasks) => uploadTasks
)

/**
 * SELECTOR: Selects complete tasks
 */
export const selectCompleteUploadTasks = createSelector(
  (state: RootState) => state.uploadDriveFileTasksState.uploadTasks as unknown as Record<string, UploadTaskStatusDrive>,
  (uploadTasks) => {
    return Object.values(uploadTasks).filter((task) => task.complete)
  }
)
