
import { useEffect } from 'react'
import { FilesDriveActions } from '../store/files-drive.slice'
import { useDispatch, useSelector } from 'react-redux'
import FilesDriveList from '../components/FilesDriveList'
import FileDriveNavigation from '../components/FileDriveNavigation'
import { useParams } from 'react-router-dom'
import { FileDriveDropZone } from '../components/FileDriveDropZone'
import { FileOverwriteModalProvider } from '../../files/file-overwrite-confirmation/file-overwrite-modal-context'
import { RootState } from '@/store/store'
import { selectCurrentFolderMeta, selectLoading } from '../store/files-drive.selectors'
import { FolderNode } from '../schemas/files-drive-schema'
function FilesDrivePage() {
  const { folderId: currentFolderId = '' } = useParams<{ folderId: string }>()


  const dispatch = useDispatch()
  // Get the current folder meta data
  const currentFolder = useSelector((state: RootState) => selectCurrentFolderMeta(state)) as FolderNode | null

  const isRootFolder = currentFolderId === '' || currentFolderId === undefined

  const loading = useSelector((state: RootState) => selectLoading(state))

  useEffect(() => {
    if (currentFolderId === '') {
      console.log('Setting current folder id', currentFolderId)
      dispatch(FilesDriveActions.setCurrentFolderId({ folderId: 'root' }))
    }
  })

  useEffect(() => {
    console.log('Effect running for folder', currentFolderId)
    dispatch(FilesDriveActions.setCurrentFolderId({ folderId: currentFolderId }))

    dispatch(FilesDriveActions.fetchItems({ folderId: currentFolderId }))
  }, [dispatch, currentFolderId])


  return (
    <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0'}>
      <div className="bg-brand-neutral-50 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6 text-red-700">New File Driver is under development</div>

        {/* To be added in new tickets: 
          - File search (Streetch goal)
          - Action dialogs for file/folder operations
       */}
        <FileOverwriteModalProvider>
          <FileDriveDropZone currentFolderId={currentFolderId} currentFolder={currentFolder} />

          <FileDriveNavigation isRootFolder={isRootFolder} />
          <FilesDriveList currentFolderId={currentFolderId} loading={loading} />
        </FileOverwriteModalProvider>
      </div>
    </div>
  )
}

export default FilesDrivePage