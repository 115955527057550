import { z } from 'zod'

//create enum for type Can be file or folder
export enum NodeType {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum FileStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed',
  ERROR = 'error',
  COMPLETE = 'complete',
  // OCR = 'ocr', To be implemented in the BE
  //TODO: remove when new file drive is deployed - Check if we need for backwards compatibility
}

export const DriveFileSchema = z.object({
  id: z.string().uuid(),
  folder_id: z.string().uuid(),
  user_id: z.string(),
  source_location: z.string(),
  markdown_location: z.string(),
  size: z.number(),
  name: z.string(),
  status: z.nativeEnum(FileStatus),
  extension: z.string(),
  folder_path: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  events: z.any().nullable(),
  parse_file_id: z.any().nullable(),
  parse_file_sentences_id: z.any().nullable(),
  error: z.any().nullable(),
})

export const DriveFolderSchema = z.object({
  id: z.string().uuid(),
  folder_id: z.string().uuid().nullable(), //root will be null
  user_id: z.string(),
  name: z.string(),

  folder_path: z.string(),
  full_visual_path: z.string(),
})

//Schema of the response from the server without the folders and files types.
export const FileDriveResponseSchema = z.object({
  folders: z.array(DriveFolderSchema).optional(),
  files: z.array(DriveFileSchema).optional(),
})

export type FilesDriveResponse = z.infer<typeof FileDriveResponseSchema>

//Adding types to schema. Types are added in the fetch drive items listener.
export type FolderNode = z.infer<typeof DriveFolderSchema> & { type: NodeType.FOLDER }
export type FileNode = z.infer<typeof DriveFileSchema> & { type: NodeType.FILE }

export type ItemNode = FolderNode | FileNode
