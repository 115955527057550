import { getAuth, signOut } from 'firebase/auth'
import { NavigateFunction } from 'react-router'
import { clearReduxStateData } from './clear-user-data'

/**
 * Sign Out Of App
 * Calls the firebase authentication sign out function
 */
export default async function signOutOfApp(navigate?: NavigateFunction) {
  const auth = getAuth()

  // Navigate to dashboard
  if (navigate) navigate('/dashboard')

  // Clear state data
  clearReduxStateData()

  // Firebase sign out
  await signOut(auth)
}
