import { FirebaseError } from 'firebase/app'
import { getAuth, linkWithPopup, linkWithRedirect, signInWithCredential, signInWithRedirect, User, SAMLAuthProvider, OAuthProvider } from 'firebase/auth'
import { getMicrosoftTenantId, isHaikuHost } from '@/util/enterprise'
import { validateSSOCredentials } from '@/util/enterprise'

// Execute the Azure Auth redirect flow - Very similar to the Google Auth redirect flow
// Firebase and Microsoft article: https://firebase.google.com/docs/auth/web/microsoft-oauth#web-modular-api
// The results of this redirect are picked up in root.tsx
export default async function azureAuthRedirect() {
  const auth = getAuth()
  const currentUser = auth.currentUser

  const provider = isHaikuHost() ? new SAMLAuthProvider('saml.haiku') : new OAuthProvider('microsoft.com')
  if (!isHaikuHost()) {
    provider.setCustomParameters({
      // Optional "tenant" parameter in case you are using an Azure AD tenant.
      // eg. '8eaef023-2b34-4da1-9baa-8bc8c9d6a490' or 'contoso.onmicrosoft.com'
      // or "common" for tenant-independent tokens.
      // The default value is "common".
      // tenant:'common'
      tenant: getMicrosoftTenantId(),
    })
  }

  try {
    if (currentUser == null) {
      // No existing user, start sign in with redirect
      console.log('Signing in with redirect...')
      signInWithRedirect(auth, provider)
    } else {
      // Existing user, link account with redirect
      console.log('Linking with redirect...')

      // Detect if we are on a preview channel based on whether the TLD is web.app
      const firebase_preview_pattern = /web\.app/

      // Use Popup only on local development or on preview channels
      if (window.location.hostname === 'localhost' || firebase_preview_pattern.test(window.location.href)) {
        localhostLinkWithPopupFlow(currentUser, provider)
      } else {
        // Redirect to dashboard to avoid coming back to the same chat id and causing a 403 error
        window.history.replaceState({}, '', '/dashboard')

        // Used when Anonymous (signed in) users are trying to sign in with this method
        linkWithRedirect(currentUser, provider)
      }
    }
  } catch (error: any) {
    if (error instanceof FirebaseError) {
      console.error('Azure auth redirect firebase error: ', error)

      // // Handle Errors here.
      // const errorCode = error.code
      // const errorMessage = error.message

      // // The email of the user's account used.
      // const email = error.customData?.email

      // TODO: Add sentry telemetry
    } else {
      // TODO: Add sentry telemetry
      console.error('Azure auth redirect error', error)
    }

    return
  }
}

// LOCALHOST Azure SIGN IN FLOW
// This is a special case for localhost development
// It uses a popup instead of a redirect to sign in
// It is designed to simulate the redirect flow that is picked up in root.tsx rather
// than use the normal popup flow
async function localhostLinkWithPopupFlow(user: User, provider: OAuthProvider | SAMLAuthProvider) {
  console.log('Initiating local dev popup AzureAuthProvider flow...')
  const auth = getAuth()

  try {
    const userCredential = await linkWithPopup(user, provider)
    console.log('Local dev popup social flow success: ', userCredential)

    // Complete SSO validation if applicable
    await validateSSOCredentials(userCredential)
  } catch (e: any) {
    if (e instanceof FirebaseError) {
      console.error('Local dev popup social flow FirebaseError: ', e)

      const errorCredential = OAuthProvider.credentialFromError(e)
      console.log('Popup Flow Error credential: ', errorCredential)

      if (errorCredential != null) {
        const idToken = errorCredential.idToken
        if (idToken == null) {
          throw new Error('Local dev popup social flow error credential idToken is null')
        }

        // Sign in with the error credential
        await signInWithCredential(auth, errorCredential)
        return
      }

      const samlErrorCredential = SAMLAuthProvider.credentialFromError(e)
      if (samlErrorCredential == null) throw new Error('Cannot sign in existing OAuth account with null eCred')

      // Sign in with the error credential
      await signInWithCredential(auth, samlErrorCredential)

      // Complete SSO validation if applicable (first time sign in with SAML from a login method)
      if (e?.code === 'auth/email-already-in-use') {
        await validateSSOCredentials(samlErrorCredential)
      }

      return
    } else {
      console.error('Local dev popup AzureAuthProvider flow error: ', e)
    }
  }
}
