import { nanoid } from 'nanoid'
import { FileDriveResponseSchema, FilesDriveResponse } from '../schemas/files-drive-schema'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import * as Sentry from '@sentry/browser'
import { kFileDriveApiPath, kFolderDriveApiPath } from '@/constants/constants-api-paths'
// import { delay } from '../store/files-drive.listeners'

/**
 * Get all files for the authenticated user
 * Sends a GET request to the backend to retrieve the user's files
 * @returns A promise that resolves to an array of files and folders
 * @throws An error if the response status is not OK
 */
export async function fetchAllItemsByFolder(folderId: string | null): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()

  try {
    //Maybe abstract this authentication methods.
    const token = await getAuth().currentUser?.getIdToken(true)

    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath + (folderId ? `/?folder_id=${folderId}` : '')

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    // await delay(1000)
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    const validatedData = FileDriveResponseSchema.safeParse(data)

    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    return data
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

/**
 * Get a specific file by ID for the authenticated user
 * Sends a GET request to the backend to retrieve the specified file
 * @param fileId The ID of the file to retrieve
 * @returns A promise that resolves to the file data
 * @throws An error if the response status is not OK
 */
export async function fetchFileById(fileId: string): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()
  console.log('Fetching file by id', fileId)
  try {
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFileDriveApiPath + `/${fileId}`

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    const validatedData = FileDriveResponseSchema.safeParse(data)

    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    return data
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

/**
 * Get a specific folder by ID for the authenticated user
 * Sends a GET request to the backend to retrieve the specified folder
 * @param folderId The ID of the folder to retrieve
 * @returns A promise that resolves to the folder data
 * @throws An error if the response status is not OK
 */
export async function fetchFolderById(folderId: string): Promise<FilesDriveResponse> {
  const sentry_transaction_id = nanoid()
  try {
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)

    const apiUrl = kPaxtonAppApiBaseUrl() + kFolderDriveApiPath + `/${folderId}`

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    const validatedData = FileDriveResponseSchema.safeParse(data)

    if (!validatedData.success) {
      throw new Error(`Failed to validate response: ${validatedData.error.message}`)
    }

    return data
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}
