import { createListenerMiddleware } from '@reduxjs/toolkit'
import { FilesDriveActions } from './files-drive.slice'
import { fetchAllItemsByFolder, fetchFileById, fetchFolderById } from '../repository/fetch-drive-items'
import { FileNode, FileStatus, FolderNode, NodeType } from '../schemas/files-drive-schema'
import { RootState } from '@/store/store'
import * as Sentry from '@sentry/browser'

const filesDriveListenerMiddleware = createListenerMiddleware()

// Delay function helper for polling file status
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.fetchItems,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as RootState
    if (state.filesDrive.loading) {
      console.warn('Fetch Items action ignored because loading is already in progress.')
      return
    }

    listenerApi.dispatch(FilesDriveActions.setLoading(true))

    try {
      const folderId = action.payload.folderId === '' ? 'root' : action.payload.folderId

      // Get folder metadata from existing state if not root
      if (folderId !== 'root') {
        const existingFolder = state.filesDrive.folders.find((f) => f.id === folderId)
        if (existingFolder) {
          listenerApi.dispatch(FilesDriveActions.setCurrentFolderMeta({ folder: existingFolder }))
        } else {
          // Fetch the folder from the backend and extract its metadata
          const response = (await fetchFolderById(folderId)) as { folder: FolderNode }
          if (response.folder) {
            listenerApi.dispatch(FilesDriveActions.setCurrentFolderMeta({ folder: { ...response.folder, type: NodeType.FOLDER } }))
          } else {
            console.warn(`Folder ID does not exist: ${folderId}`)
            listenerApi.dispatch(FilesDriveActions.setCurrentFolderMeta({ folder: null }))
          }
        }
      }

      // Fetch the folder contents
      const data = await fetchAllItemsByFolder(folderId)

      // Separate folders and files and add type to each - This is done to ensure that each item has a type property for sorting purposes.
      const folders = data.folders?.map((f) => ({ ...f, type: NodeType.FOLDER })) as FolderNode[]
      const files = data.files?.map((f) => ({ ...f, type: NodeType.FILE })) as FileNode[]

      listenerApi.dispatch(FilesDriveActions.setFolderItems({ folders, files }))
      //Set polling state to true to start polling and updating the files statuses
      listenerApi.dispatch(FilesDriveActions.setPollingFilesActive({ isActive: true }))
      listenerApi.dispatch(FilesDriveActions.setLoading(false))
    } catch (error) {
      console.error('Error fetching items for folder:', error)

      listenerApi.dispatch(FilesDriveActions.setError(error as string))
      listenerApi.dispatch(FilesDriveActions.setLoading(false))
    }
  },
})

filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.setPollingFilesActive,
  effect: async (_action, listenerApi) => {
    const startTime = Date.now()
    // Max polling time (5 minutes)
    const maxPollingTime = 5 * 60 * 1000
    // Polling interval time (3 seconds)
    const pollingIntervalTime = 3000
    // Retry wait time (15 seconds)
    const retryWaitTime = 15000

    const state = listenerApi.getState() as RootState

    if (!state.filesDrive.pollingFilesActive) {
      console.warn('Polling is not active')
      return
    }

    const executePoll = async () => {
      const state = listenerApi.getState() as RootState
      const { pollingFilesActive } = state.filesDrive

      while (pollingFilesActive) {
        const duration = Date.now() - startTime
        const state = listenerApi.getState() as RootState

        if (duration > maxPollingTime) {
          Sentry.captureException(new Error('Max polling time reached for artifact version'), {
            extra: {
              onLine: navigator.onLine,
              cookieEnabled: navigator.cookieEnabled,
              pollingMinutes: duration / 60000,
            },
          })
          console.warn(`Stopping polling due to max duration.`)
          listenerApi.dispatch(FilesDriveActions.setPollingFilesActive({ isActive: false }))
          return
        }

        const processingFiles = state.filesDrive.files.filter((f) => f.status === FileStatus.PROCESSING)

        if (processingFiles.length === 0) {
          listenerApi.dispatch(FilesDriveActions.setPollingFilesActive({ isActive: false }))
          return
        }

        for (const processingFile of processingFiles) {
          try {
            await delay(pollingIntervalTime)

            const { file } = (await fetchFileById(processingFile.id)) as { file: FileNode }

            if (!file) {
              console.error(`No files found for file ID: ${processingFile.id}`)
              listenerApi.dispatch(FilesDriveActions.updateFileStatus({ fileId: processingFile.id, status: FileStatus.FAILED }))
            }

            if (file.status !== FileStatus.PROCESSING) {
              listenerApi.dispatch(FilesDriveActions.updateFileStatus({ fileId: file.id, status: file.status }))
            }
          } catch (e) {
            console.warn(`Error polling file ID: ${processingFile.id}`, e)
            await delay(retryWaitTime)
          }
        }
      }
    }
    await executePoll()
  },
})

export default filesDriveListenerMiddleware
