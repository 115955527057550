import { z } from 'zod'
import { ChatV2Feature, ChatV2MessageReferenceSchema, ChatV2MessageType } from '../store/chat-v2.slice'
import { ChatV2SuggestedQuestionsResponseSchema } from '../fetch/fetch-suggested-questions'

// Chat message metadata schema and type (from zod schema)
export const ChatV2MessageMetadataSchema = z.object({
  feature: z.nativeEnum(ChatV2Feature, {
    invalid_type_error: 'Feature enum provided is invalid.',
    required_error: 'Feature enum required.',
  }),
  message_type: z.nativeEnum(ChatV2MessageType, {
    invalid_type_error: 'Actor enum provided is invalid.',
    required_error: 'Actor enum required.',
  }),
  created_at: z.preprocess((val, ctx) => {
    // Try to parse it (should be provided as a python datetime string)
    const timestamp = Date.parse(val as string)
    if (isNaN(timestamp)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Provided timestamp (${val}) failed to Date.parse()`,
      })
      return z.NEVER // Return a value that will fail the schema
    }
    return timestamp
  }, z.number()),
  conversation_id: z.string(),
  message_id: z.string(),
  is_error: z.boolean(),
  get_follow_up_questions: z.boolean(),
  suggested_questions: ChatV2SuggestedQuestionsResponseSchema.optional(),
  suggested_questions_loading: z.boolean().optional(),
  is_prompt_assist: z.boolean().optional(),
  references: z.record(ChatV2MessageReferenceSchema).optional(),
})
export type ChatV2MessageMetadataType = z.infer<typeof ChatV2MessageMetadataSchema>

// Message Schema (maps to backend ChatResponseObject)
export const ChatV2MessageSchema = z.object({
  text: z.string(),
  metadata: ChatV2MessageMetadataSchema,
  request_params: z.any().optional(),
  confidence_label: z.string().nullable().optional(),
})
export type ChatV2Message = z.infer<typeof ChatV2MessageSchema>

// Full Conversation Response (maps to backend GetConversationResponse)
// Full conversation object from REST API
export const ChatV2GetConversationByIdResponseSchema = z.object({
  id: z.string(),
  title: z.string(),
  feature: z.nativeEnum(ChatV2Feature),
  modified_on: z.preprocess((val, ctx) => {
    // Try to parse it (should be provided as a python datetime string)
    const timestamp = Date.parse(val as string)
    if (isNaN(timestamp)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Provided timestamp (${val}) failed to Date.parse()`,
      })
      return z.NEVER // Return a value that will fail the schema
    }
    return timestamp
  }, z.number()),
  messages: z.array(ChatV2MessageSchema),
})

// When the websocket receives a full messages instead of just a streaming response.
export const ChatV2WebSocketFullResponseSchema = z.object({
  text: z.string(),
  metadata: ChatV2MessageMetadataSchema,
})
export type ChatV2FullResponseType = z.infer<typeof ChatV2WebSocketFullResponseSchema>

export const ChatV2MessageReferencesSchema = z.object({
  conversation_id: z.string(),
  message_id: z.string(),
  references: z.record(ChatV2MessageReferenceSchema),
})
export type ChatV2MessageReferencesType = z.infer<typeof ChatV2MessageReferencesSchema>
