import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'
import { AuthContext } from '@/context/auth-context'
import { FileNode, useProcessedFiles } from './hooks/useProcessedFiles'
import { RootState } from '@/store/store'
import { selectCurrentSourceFiles } from '@/chat-common/store/chat-v2.selectors'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'
import { Folder, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { kGcsOrgHiddenFolder, kHiddenFileForFolderName } from '@/constants/constants-gcs'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { useAppSelector } from '@/store/store-hooks'
import { getFileStatus } from './utils/get-file-status'
import DynamicFileStatusIcon from './utils/file-status'

enum ActiveDriveTab {
  MYDRIVE = 'MYDRIVE',
  SHAREDDRIVE = 'SHAREDDRIVE',
}

type DispatchFunctions = {
  addFileSelection: (file: string) => void
  toggleFileSelection: (file: string) => void
  clearFilesSelection: () => void
}

type FileDriveSelectorProps = {
  conversationId: string
  open: boolean
  featureName: ChatV2Feature
  dispatchFunctions: DispatchFunctions
  onClose: (value: boolean) => void
  allowMultipleSelection?: boolean
}

/**
 * Component for selecting files and folders from a user's drive or shared drive.
 *
 * @component
 * @param {FileDriveSelectorProps} props - The properties for the component.
 * @param {boolean} props.open - Determines if the dialog is open.
 * @param {string} props.conversationId - The ID of the conversation.
 * @param {string} props.featureName - The name of the feature.
 * @param {Object} props.dispatchFunctions - The dispatch functions for file selection.
 * @param {Function} props.dispatchFunctions.addFileSelection - Function to add a file selection.
 * @param {Function} props.dispatchFunctions.toggleFileSelection - Function to toggle a file selection.
 * @param {Function} props.dispatchFunctions.clearFilesSelection - Function to clear all file selections.
 * @param {Function} props.onClose - Function to handle closing the dialog.
 * @param {boolean} [props.allowMultipleSelection=true] - Determines if multiple file selection is allowed.
 *
 * @returns {JSX.Element | null} The rendered component or null if not open.
 *
 * @example
 * <SourceSelectionDialog
 *   open={true}
 *   conversationId="123"
 *   featureName="feature"
 *   dispatchFunctions={{
 *     addFileSelection: (id) => console.log(id),
 *     toggleFileSelection: (id) => console.log(id),
 *     clearFilesSelection: () => console.log('clear')
 *   }}
 *   onClose={() => console.log('close')}
 * />
 */
export const SourceSelectionDialog: React.FC<FileDriveSelectorProps> = ({
  open,
  conversationId,
  featureName,
  dispatchFunctions: { addFileSelection, toggleFileSelection, clearFilesSelection },
  onClose,
  allowMultipleSelection = true,
}) => {
  const [selectedTab, setSelectedTab] = useState<ActiveDriveTab>(ActiveDriveTab.MYDRIVE)
  const [searchQuery, setSearchQuery] = useState('')

  const [expandedFolders, setExpandedFolders] = useState<Array<string>>([])
  const [selectedFiles, setSelectedFiles] = useState<Array<string>>([])

  const [addingFiles, setAddingFiles] = useState<boolean>(false)

  const inactiveDriveStyles =
    'grow border-transparent text-brand-neutral-600 hover:border-brand-neutral-700 hover:text-brand-neutral-700 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
  const activeDriveStyles = ' grow border-brand-400 text-brand-500 w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'

  const { userAccountData } = useContext(AuthContext)
  const auth = getAuth()
  const uid = auth.currentUser?.uid

  const source = useAppSelector((state: RootState) => selectCurrentSourceFiles(state, { chatId: conversationId, featureName }))

  const organizationId = userAccountData?.legacyDriveCompatibleOrganizationId
  const brand = userAccountData?.brand

  const driveRootPath = selectedTab == ActiveDriveTab.MYDRIVE ? `users/${uid}` : `tenants/${organizationId}`

  const { loading, files, expandFolder } = useProcessedFiles({ path: driveRootPath })

  useEffect(() => {
    if (source) {
      setSelectedFiles(Array.isArray(source) ? source : [source])
    }
  }, [source])

  // Event Handlers
  const handleTabChange = (tab: ActiveDriveTab) => {
    setSelectedTab(tab)
  }

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>, file: FileNode) => {
    const isSelected = e.target.checked

    if (selectedFiles.includes(file.id) && !isSelected) {
      setSelectedFiles((prev) => prev.filter((id) => id !== file.id))
      toggleFileSelection(file.id)
      return
    }

    if (isSelected && !selectedFiles.includes(file.id)) {
      setSelectedFiles((prev) => [...prev, file.id])
      addFileSelection(file.id)
      return
    }
  }

  const handleFolderSelection = async (e: React.ChangeEvent<HTMLInputElement>, folder: FileNode) => {
    const isSelected = e.target.checked

    if (!folder.children) return

    // Expanding the folder when the folder is selected.
    if (!expandedFolders.includes(folder.id) && isSelected) {
      handleFolderExpandToggle(folder.id)
    }
    try {
      // Show loading cursor while selecting children files
      setAddingFiles(true)
      await expandFolder(folder)

      // When the expandFolder promise is done, select all the files in the folder.
      if (folder.children) {
        folder.children.forEach((child) => {
          // Don't select the file if it doesn't match the search query - This will only select files that are visible when search is active.
          if (searchQuery !== '' && !child.name.toLowerCase().includes(searchQuery.toLowerCase())) return

          addFileSelection(child.id)
          setSelectedFiles((prev) => [...prev, child.id])
        })
      }
      setAddingFiles(false) //Remove loading cursor
    } catch (error) {
      console.error('Error selecting folder', error)
    }

    //Remove all files from the folder if the folder is unselected
    if (!isSelected) {
      setAddingFiles(true)

      // Remove individually selected files within this folder
      if (folder.children) {
        folder.children.forEach((child) => {
          toggleFileSelection(child.id) //dispatch the file selection
          setSelectedFiles((prev) => prev.filter((id) => id !== child.id))
        })
      }
      setAddingFiles(false)
    }
  }

  const handleFolderExpandToggle = (folderId: string) => {
    const folder = filesToRender.find((file) => file.id === folderId)
    const isExpanded = expandedFolders.includes(folderId) && folder?.isExpanded

    if (isExpanded) {
      // LAZY-LOADING - change the UI but don't recall the contents of the folder for now
      setExpandedFolders((prev) => prev.filter((id) => id !== folderId))
    } else {
      if (folder && !folder.isExpanded) {
        expandFolder(folder)
      }
      setExpandedFolders((prev) => [...prev, folderId])
    }
  }

  const handleClearAllSelections = () => {
    setSelectedFiles([])
    setExpandedFolders([])
    setSearchQuery('')
    clearFilesSelection()
  }

  const isFolderSelected = (folder: FileNode) => {
    if (!folder.children) return false

    // If all children of a folder are selected, the folder should be selected
    const children = folder.children || []
    const allChildrenSelected = folder.children.length > 0 && children.every((child) => selectedFiles.includes(child.id))
    return allChildrenSelected
  }

  const isFileSelected = (fileId: string) => {
    const isInSource = Array.isArray(source) ? source.includes(fileId) : source === fileId

    return isInSource || selectedFiles.includes(fileId)
  }

  const filterFilesRecursively = useCallback(
    (files: FileNode[], query: string): FileNode[] => {
      return files.reduce((acc: FileNode[], file: FileNode) => {
        if (file.isFolder) {
          // Always include folders in the search results
          let children = file.children

          if (expandedFolders.includes(file.id) && children) {
            // If the folder is expanded and has children, filter its children
            children = filterFilesRecursively(children, query)
          }

          acc.push({
            ...file,
            children,
          })
        } else if (file.name == kHiddenFileForFolderName) {
          // Skip hidden files
          return acc
        } else {
          // For root files, apply the filter
          if (file.name.toLowerCase().includes(query.toLowerCase())) {
            acc.push(file)
          }
        }
        return acc
      }, [])
    },
    [searchQuery, expandedFolders]
  )

  const filesToRender = files.filter((file) => file.name !== kGcsOrgHiddenFolder || !file.name.endsWith(kHiddenFileForFolderName))

  const filesToRenderPostSearch = filterFilesRecursively(filesToRender, searchQuery)

  //Rendering Files and Folders Recursively
  const renderFiles = (files: FileNode[], level = 1) => {
    return files.map((file) => (
      <Combobox.Option key={file.id} value={file} as={Fragment}>
        {file.isFolder ? (
          <>
            <div
              className={`cursor-default select-none px-4 py-2 items-center border-t-[1px] text-wrap grid hover:bg-brand-neutral-100 ${
                allowMultipleSelection && 'grid-cols-[24px_auto]'
              }`}
            >
              {allowMultipleSelection && (
                <input
                  type="checkbox"
                  checked={isFolderSelected(file)}
                  onChange={(e) => handleFolderSelection(e, file)}
                  className={`h-4 w-4 rounded border-gray-300 text-brand-500 focus:ring-brand-500 disabled:bg-brand-neutral-100 disabled:cursor-not-allowed ${
                    addingFiles && 'cursor-wait'
                  }`}
                  disabled={!file.children}
                />
              )}
              <label
                onClick={() => handleFolderExpandToggle(file.id)}
                className={'pl-1 pr-2 grid grid-cols-[28px_auto_32px] items-center text-sm cursor-pointer text-wrap'}
              >
                <Folder />
                <span className="ml-2">{file.name}</span>
                {expandedFolders.includes(file.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </label>
            </div>
            <div style={{ paddingLeft: `${level * 20}px` }}>
              {expandedFolders.includes(file.id) && file.children && renderFiles(file.children, level + 1)}
              {file.isLoading && (
                <div className="flex justify-center m-4">
                  <CircularProgressContinuousSized size={25} thickness={5} />
                </div>
              )}
              {!file.isLoading && file.isExpanded && file.children && file.children.length === 0 && (
                <div className="ml-2 px-4 py-2 text-gray-500">No files found</div>
              )}
            </div>
          </>
        ) : (
          <div className={`cursor-default select-none px-4 py-2 items-center border-t-[1px] grid grid-cols-[24px_auto] hover:bg-brand-neutral-100`}>
            <input
              id={`file-checkbox-${file.id}`}
              type="checkbox"
              checked={isFileSelected(file.id)}
              onChange={(e) => handleFileSelection(e, file)}
              className="h-4 w-4 rounded border-gray-300 text-brand-500 focus:ring-brand-500 disabled:bg-brand-neutral-100 disabled:cursor-not-allowed"
              disabled={getFileStatus(file).status !== 'complete'}
            />
            <label className="pl-1 pr-2 items-center cursor-pointer grid-cols-[32px_auto]" htmlFor={`file-checkbox-${file.id}`}>
              <DynamicFileStatusIcon file={file} />
              <span className="ml-2">{file.name}</span>
            </label>
          </div>
        )}
      </Combobox.Option>
    ))
  }

  if (!open) return null
  return (
    <Transition.Root show={open} as={Fragment} appear>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto px-4 py-20 lg:ml-[300px]">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform rounded-xl bg-white p-2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              {/* Drive Choice Tabs - Only for users with an organization */}
              {organizationId && brand != BrandEnum.HAIKU && (
                <div className="border-b border-gray-200 mb-2">
                  <nav className="w-full flex items-center" aria-label="Tabs">
                    <button
                      onClick={() => handleTabChange(ActiveDriveTab.MYDRIVE)}
                      className={selectedTab === ActiveDriveTab.MYDRIVE ? activeDriveStyles : inactiveDriveStyles}
                    >
                      My Drive
                    </button>

                    <button
                      onClick={() => {
                        handleTabChange(ActiveDriveTab.SHAREDDRIVE)
                      }}
                      className={selectedTab === ActiveDriveTab.SHAREDDRIVE ? activeDriveStyles : inactiveDriveStyles}
                    >
                      Shared Drive
                    </button>
                  </nav>
                </div>
              )}

              <Combobox>
                {/* Search only shows if there are files */}
                {filesToRender.length > 0 && (
                  <Combobox.Input
                    className="w-full rounded-md border-0 bg-gray-100 px-4 py-2.5 text-gray-900 focus:ring-0 sm:text-sm"
                    value={searchQuery} // Prevents the default behavior of the input field to submit form on enter
                    placeholder="Search..."
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  />
                )}
                <div className={'overflow-y-scroll'}>
                  <Combobox.Options static className="-mb-2 max-h-96 sm:max-h-[600px] scroll-py-2 py-2 text-sm text-gray-800">
                    {loading ? (
                      <div className="flex justify-center m-4">
                        <CircularProgressContinuousSized size={30} thickness={5} />
                      </div>
                    ) : (
                      // Recursively render folders/files
                      renderFiles(filesToRenderPostSearch)
                    )}
                  </Combobox.Options>
                </div>
                <div className={'flex'}>
                  <button
                    type={'button'}
                    onClick={() => handleClearAllSelections()}
                    className={
                      'flex-auto items-center rounded-md bg-white border-[1px] border-brand-500 px-3 py-2 m-2 text-sm font-semibold text-brand-500 shadow-sm hover:bg-brand-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
                    }
                  >
                    Clear
                  </button>
                  <button
                    type={'button'}
                    onClick={() => onClose(false)}
                    className={
                      'flex-auto items-center rounded-md bg-brand-500 px-3 py-2 m-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
                    }
                  >
                    Done
                  </button>
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
