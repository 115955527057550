import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileNode, FileStatus, FolderNode } from '../schemas/files-drive-schema'

export type FilesDriveState = {
  folders: FolderNode[]
  files: FileNode[]
  loading: boolean
  error: string | null
  currentFolderId: string | null
  currentFolderMeta: FolderNode | null
  pollingFilesActive: boolean
}

const initialState: FilesDriveState = {
  folders: [],
  files: [],
  loading: false,
  error: null,
  currentFolderId: null,
  currentFolderMeta: null,
  pollingFilesActive: false,
}

export const filesDriveSlice = createSlice({
  name: 'filesDrive',
  initialState,
  reducers: {
    //Disable since we will use the folderId for the fetchItems action.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchItems: (_state, _action: PayloadAction<{ folderId: string }>) => {
      // handled by the listener middleware
    },
    //Disable since we will use the folderId for the fetchItems action.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchFileStatusById: (_state, _action: PayloadAction<{ fileId: string }>) => {
      // handled by the listener middleware
    },
    setCurrentFolderId: (state, action: PayloadAction<{ folderId: string }>) => {
      if (action.payload.folderId) {
        state.currentFolderId = action.payload.folderId
      }
    },
    setCurrentFolderMeta: (state, action: PayloadAction<{ folder: FolderNode | null }>) => {
      state.currentFolderMeta = action.payload.folder ?? null
    },
    updateFileStatus: (state, action: PayloadAction<{ fileId: string; status: FileStatus }>) => {
      const { fileId, status } = action.payload
      const file = state.files.find((f) => f.id === fileId)
      if (file) {
        file.status = status
      }
    },
    setFolderItems: (state, action: PayloadAction<{ folders: FolderNode[]; files: FileNode[] }>) => {
      const { folders, files } = action.payload

      if (!folders && !files) {
        state.error = 'Failed to fetch items.'
        state.loading = false
        return
      }

      const rootFolder = folders.find((f) => !f.folder_id)

      if (rootFolder) {
        state.currentFolderId = rootFolder.id
      }

      // Filter out the root folder from the folders array.
      state.folders = folders.filter((f) => f.folder_id !== null)
      // Ensure that state.files is always an array to prevent potential runtime issues.
      state.files = Array.isArray(files) ? files : []
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
      state.loading = false
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPollingFilesActive: (state, action: PayloadAction<{ isActive: boolean }>) => {
      state.pollingFilesActive = action.payload.isActive
    },
  },
})

export const FilesDriveActions = filesDriveSlice.actions
export default filesDriveSlice.reducer
